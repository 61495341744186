import * as React from 'react';
import { IconButton, AppBar, Toolbar, CssBaseline, Avatar, Stack, Box, useTheme } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, Menu as MenuIcon, Person as PersonIcon } from '@mui/icons-material';
import LogoIcon from "assets/logo.png";
import Image from "../image";
import InputTitle from "../titleinput";
import CustomEditIcon from "../eicon";
import TimerSession from 'shared/useTimerSession';
import Session from 'shared/session';
import NavigationLogo from 'screens/popup/logoselection';
import { GetDocumentsApi } from 'shared/services';
import Helper from "shared/helper";

const Component = ({ open, onDrawerClicked }) => {
    const [frontendAppLogo] = TimerSession("FrontendAppLogo");
    const [companyTitle] = TimerSession("CompanyName", null, "XYZ Company");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [logo, setLogo] = React.useState(LogoIcon);
    const [initDocument, setInitDocument] = React.useState(false);
    const theme = useTheme();

    const OnInputChanged = (e) => {
        Session.Store("CompanyName", e);
    }

    const OnLogoClicked = () => {
        setOpenDialog(true);
    }

    const OnDialogClosed = (e) => {
        if (e) setLogo(e);
        setOpenDialog(false);
    }

    const GetLogoIcon = async () => {
        await GetDocumentsApi(frontendAppLogo)
            .then(async (doc) => {
                let data = null;
                if (!Helper.IsNullValue(doc)) {
                    if (doc.startsWith("data:")) {
                        data = doc.substring(doc.indexOf('data:'));
                    } else {
                        let tmp = doc.split('\r\n');
                        for (let img of tmp) {
                            if (img.startsWith("data:")) data = img;
                        }
                    }
                }
                setLogo(data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    if (initDocument) {
        setInitDocument(false);
        GetLogoIcon();
    }


    React.useEffect(() => {
        if (frontendAppLogo) {
            setInitDocument(true);
        }
    }, [frontendAppLogo]);

    return (
        <>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => onDrawerClicked()}>
                        {!open ? <MenuIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                    <Box sx={{ display: "flex", border: theme.borderColor, p: 0, m: 0, mr: "5px", width: "40px", position: "relative" }}>
                        <Image sx={{ width: 40, height: 40, mr: 2, display: "blcok" }} alt="logo" src={logo} />
                        <CustomEditIcon sx={{ position: "absolute", bottom: 0, right: 0, p: 0 }} onClick={OnLogoClicked} />
                    </Box>
                    <InputTitle value={companyTitle} color={"#ffffff"} sx={{ color: "#ffffff", flexGrow: 1 }} onTitleChanged={OnInputChanged} />
                    <Stack direction={"row"} sx={{ display: "flex", alignItems: "center", flexGrow: 1, flexWrap: "nowrap", justifyContent: "flex-end" }}>
                        {/* <Typography noWrap>{themeLabel}</Typography><Switch value="active" onChange={onSwitchChanged} checked={themeName === 'Dark'}></Switch> */}
                        {/* <Typography variant="avatar" noWrap component="div" sx={{ marginRight: 1 }}>Welcome! User</Typography> */}
                        <Avatar
                            style={{ cursor: "pointer" }}
                        ><PersonIcon />
                        </Avatar>
                    </Stack>
                </Toolbar>
            </AppBar>
            {openDialog && (
                <NavigationLogo onDialogClosed={OnDialogClosed} />
            )}
        </>
    );
}

export default Component;
