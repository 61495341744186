import React from "react";
import {
    Typography, Dialog, TextField,
    DialogTitle, DialogContent, DialogActions, Button
} from "@mui/material";
import Helper from "shared/helper";

const Component = (props) => {
    const { setOpenDialog, openDialog, entity, onPropertySelected } = props;

    const [screenName, setScreenName] = React.useState("");
    const [error, setError] = React.useState(null);

    const OnScreenNameChanged = (e) => {
        e.preventDefault();
        setError(null);
        setScreenName(e.target.value)
    }
    const OnButtonClicked = (e, bClose) => {
        e.preventDefault();
        if (!bClose) {
            if (Helper.IsNullValue(screenName)) {
                setError("Enter screen name");
                return;
            } else if (screenName === entity.ScreenLayout) {
                setError("Enter different screen name");
                return;
            }
            entity.ScreenLayout = screenName;
            onPropertySelected(entity);
        }
        setOpenDialog(false);
    }

    React.useEffect(() => {
        setError(null);
        setScreenName("");
    }, [openDialog]);


    return (
        <>
            <Dialog open={openDialog} style={{ top: -100 }}>
                <DialogTitle sx={{ m: 0, p: 2 }} style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ whiteSpace: "nowrap", paddingTop: 10 }}>Change Screen Name</div>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="navPopupBodyRow" style={{ marginTop: 1 }}>
                        <Typography className="navPopupBodyColumnLabel">Name:</Typography>
                        <Typography className="navPopupBodyColumnText">{entity?.ScreenLayout}</Typography>
                    </div>
                    <div className="navPopupBodyRow" style={{ marginTop: 1 }}>
                        <Typography className="navPopupBodyColumnLabel" sx={{ alignSelf: "center" }}>New Name:</Typography>
                        <div className="navPopupBodyColumnText">
                            <TextField size="small"
                                sx={{ width: "unset" }}
                                value={Helper.ToString(screenName)} onChange={OnScreenNameChanged} />
                        </div>
                    </div>
                    {error && <Typography sx={{ width: "100%", color: "red", textAlign: "center" }}>{error}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus onClick={(e) => OnButtonClicked(e, false)}>Ok</Button>
                    <Button variant="outlined" onClick={(e) => OnButtonClicked(e, true)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Component;