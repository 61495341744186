import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Grid, IconButton, Box, Typography, Menu, MenuItem, useTheme, Button, Divider } from "@mui/material";
import { Card, CardMedia, CardHeader } from "@mui/material";
import { SetTemplatesApi, PatchAppInfoApi } from "shared/services";
import Session from "shared/session";
import Helper from "shared/helper";
import RenamePopup from "./popup/renamePopup";
import { DropDown, InputTitle } from "components";
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import ScreenUpdate from "shared/screen";

const options = ['Rename', 'Duplicate', 'Delete'];

const ThumbNail = ({ title, img, url, index, TeScreenId, onScreenListModified, folderLevel }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const NavigateTo = useNavigate();

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e) => {
        setAnchorEl(null);
        const type = e.target.outerText;
        if (!Helper.IsNullValue(type)) {
            if (onScreenListModified) onScreenListModified({ ScreenLayout: title, TeScreenId, type, index });
        }
    };

    const onThumbNailClicked = (e, link) => {
        e.preventDefault();
        const q = Helper.GetUrlQueryParams();
        const url = `${link}?${q}&screenId=${TeScreenId}&screenIndex=${index}`;
        const lastUrl = window.location.pathname;
        NavigateTo(url);
        if (lastUrl === link) NavigateTo(0);
    }

    return (
        <Card sx={{ marginTop: 1, border: theme.borderColor }}>
            <CardMedia
                component="img"
                sx={{ cursor: "pointer", padding: "0.2em 0.2em 0 0.2em", height: "100px", objectFit: "contain" }}
                image={`${Helper.ToString(folderLevel)}${img}`}
                alt="Screen Layout Sample Image"
                onClick={(e) => onThumbNailClicked(e, url)}
            />
            <CardHeader
                action={
                    <>
                        <IconButton aria-label="settings" size="small" sx={{ mt: "-16px", p: 0 }} onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            {options.map((option) => (
                                <MenuItem key={option} onClick={handleClose}
                                    sx={{
                                        "&:hover": {
                                            color: "#ff0000",
                                            "&.MuiMenuItem-root .MuiListItemIcon-root .MuiSvgIcon-root": {
                                                color: "#ff0000",
                                            }
                                        }
                                    }}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                }
                sx={{ height: 21 }}
                titleTypographyProps={{ fontSize: "1rem", fontWeight: "bold" }}
                title={title}
            />
        </Card>
    )
}

const Component = (props) => {
    const { children, screens, entities, styles, clear, stepper, template, landingPage, folderLevel,
        enableSave, onEntitySelected, onSaveClicked, checkNavigationProp } = props;

    const [openDialog, setOpenDialog] = useState(false);
    const [reloadNewUrl, setReloadNewUrl] = useState(false);
    const [selectedScreen, setSelectedScreen] = useState({});
    const [selectedEntity, setSelectedEntity] = useState("NONE");
    const [filteredEntities, setFilteredEntities] = useState([]);
    const [pageTitle, setPageTitle] = useState("");
    const { screenId, screenIndex } = Helper.GetQueryParams();

    const tStyles = { padding: 15, position: "relative", marginBottom: "16px" };
    const _className = screens && screens.length > 0 ? "product-list" : "product-list-full";
    const theme = useTheme();
    const NavigateTo = useNavigate();

    const OnSaveClicked = (e) => {
        e.preventDefault();
        if (onSaveClicked) onSaveClicked();
    }

    const GetEnumFieldInfo = (entityName, fieldName) => {
        let obj = filteredEntities.find(x => x.Name === entityName);
        const defa = { IsEnumType: false, TypeName: null };
        if (obj) {
            const tmp = obj.Properties.find(z => z.Name === fieldName) || defa;
            return { IsEnumType: tmp.IsEnumType, TypeName: tmp.TypeName };
        }
        return defa;
    }

    const GetFormattedProps = async () => {

        let collection = await Session.RetrieveAsync('collection', true) || [];
        let screenList = await Session.RetrieveAsync('screens', true) || [];
        let screenInfos = [];

        for (let i = 0; i < collection.length; i++) {
            let item = collection[i];

            const _templateScreen = screenList.find(x => parseInt(x.TeScreenId) === parseInt(item.FrontendScreenTemplateScreen) &&
                parseInt(x.index) === parseInt(item.screenIndex));

            let flds = [];
            const fldNames = ['EntityType', 'FieldDesc', 'FieldType', 'Label', 'Name', 'NavFieldName', 'Type'];
            const _props = Object.values(item.properties);

            for (let i = 0; i < _props.length; i++) {
                let _item = {};
                for (let j = 0; j < fldNames.length; j++) {
                    _item[fldNames[j]] = _props[i][fldNames[j]];
                }
                _item.FieldSequenceNumber = _props[i]['seqNumber'];
                if (_item.EntityType === _item.Type) _item.Type = null;
                const { IsEnumType, TypeName } = GetEnumFieldInfo(item.EntityTypeName, _item.Name);
                if (IsEnumType) {
                    _item.FieldType = "EnumType";
                    _item.Type = TypeName;
                }

                flds.push(_item);
            }

            const screenInfo = {
                ScreenDescription: item.ScreenDescription,
                FrontendScreenTemplateScreen: parseInt(item.FrontendScreenTemplateScreen),
                EntityTypeName: item.ParentEntityType,
                Attribute3: _templateScreen.ScreenLayout,
                ScreenName: Helper.ReplaceAll(`${item.ParentEntityType}_${_templateScreen.ScreenName}`, " ", "_").toLowerCase(),
                screenIndex: item.screenIndex,
                UIComponents: [{
                    Name: item.EntityTypeName, //_templateScreen.ScreenName,
                    EntityTypeName: item.EntityTypeName,
                    NavigationName: item.NavigationName,
                    Title: item.EntityTypeName, //_templateScreen.ScreenName,
                    Fields: flds
                }]
            };
            screenInfos.push(screenInfo);
        }

        const _screenList = screenList.map(x => { return { TeScreenId: x.TeScreenId, screenIndex: x.index } });

        let screenUIList = [];

        for (let i = 0; i < _screenList.length; i++) {
            let _tmp = screenInfos.filter(x =>
                parseInt(x.FrontendScreenTemplateScreen) === parseInt(_screenList[i].TeScreenId) &&
                parseInt(x.screenIndex) === parseInt(_screenList[i].screenIndex)
            );
            if (_tmp && _tmp.length > 0) {
                let UIComponents = [];
                _tmp.map(x => x.UIComponents).forEach(x => UIComponents.push(x[0]));
                let newScreenInfo = { ..._tmp[0], UIComponents };
                newScreenInfo.Attribute1 = newScreenInfo.UIComponents.map(x => x.EntityTypeName).join(",");
                delete newScreenInfo['screenIndex'];
                screenUIList.push(newScreenInfo);
            }
        }

        return screenUIList;
    }

    const OnUpdateClicked = async (e) => {
        e.preventDefault();

        let screenInfos = await GetFormattedProps();
        const Company_name = await Session.RetrieveAsync("CompanyName") || "XYZ Company";
        const FrontendAppLogo = await Session.RetrieveAsync("FrontendAppLogo");
        const Screens = await Session.RetrieveAsync("screens", true);

        const menuItems = Screens.filter(x => x.IsNavigable).map(z => {
            return {
                MenuItemFScreen: z.TeScreenId,
                Label: z.ScreenLayout,
                URL: z.ScreenURL,
                Attribute1: z.icon
            }
        });

        const data = {
            FrontendAppLogo: parseInt(FrontendAppLogo), Company_name,
            //AppId: template.cAppId,
            FrontendAppTemplate: template.templateId,
            SelectedScreenIds: template.selectedScreenIds.split(",").map((x) => parseInt(x)),
            SelectedScreens: screenInfos,
            Menu: { Items: menuItems }
        };

        global.Busy(true);
        const { status, appId } = await SetTemplatesApi(data);
        if (status) {
            const { status } = await PatchAppInfoApi(template?.cAppId, { AppConfigurationFrontendApp: appId });
            global.Busy(false);
            if (status) {
                Session.Remove("collection");
                global.AlertPopup("success", "Record is created successful!");
            } else {
                global.AlertPopup("error", "Something went wroing while creating record!");
            }
        } else {
            global.Busy(false);
            global.AlertPopup("error", "Failed uploading image!");
        }
    }

    const OnScreenListModified = (e) => {
        if (e.type === 'Rename') {
            setSelectedScreen(e);
            setOpenDialog(true);
        }
        else if (e.type === 'Duplicate') ScreenUpdate.DuplicateScreen(e);
        else if (e.type === 'Delete') ScreenUpdate.Delete(e);
    }

    const OnScreenNameChanged = (e) => {
        ScreenUpdate.Rename(e);
    }

    const OnEntitySelected = (e) => {
        const { value } = e.target;
        setSelectedEntity(value);
        if (onEntitySelected) {
            let _properties = null;
            if (value !== 'NONE') {
                _properties = filteredEntities.find((x) => x.Name === value).Properties;
            }
            onEntitySelected(_properties);
        }
    }

    const OnTitleChanged = (e) => {
        let data = {
            type: "Rename",
            TeScreenId: parseInt(screenId),
            index: parseInt(screenIndex),
            ScreenLayout: e
        };
        ScreenUpdate.Rename(data);
        setPageTitle(e);
    }

    const RedirectNewUrl = () => {
        const _layout = screens.find(x => x.TeScreenId === parseInt(screenId));
        if (_layout) {
            const q = Helper.GetUrlQueryParams();
            const url = `${window.location.pathname}?${q}&screenId=${_layout.TeScreenId}&screenIndex=${_layout.index}`;
            // eslint-disable-next-line
            return NavigateTo(url);
        }
    }

    if (reloadNewUrl) {
        setReloadNewUrl(false);
        RedirectNewUrl();
    }

    useEffect(() => {
        if (screens && screens.length > 0 && screenId && !Helper.IsNullValue(screenIndex)) {
            let _layout = screens.find(x => x.TeScreenId === parseInt(screenId) && parseInt(x.index) === parseInt(screenIndex));;
            if (!_layout) {
                setReloadNewUrl(true);
            } else {
                setPageTitle(_layout.ScreenLayout);
            }
        }
    }, [screens, screenId, screenIndex]);

    useEffect(() => {
        setSelectedEntity('NONE');
    }, [clear]);

    useEffect(() => {
        let tmp = entities;
        tmp = entities.filter(x => !x.HasStream);
        if (checkNavigationProp) {
            tmp = tmp.filter(x => x.HasNavigate);
        }
        setFilteredEntities(tmp);
    }, [entities, checkNavigationProp]);


    return (
        <>
            <div className="product-container">
                {!landingPage ? (
                    <>
                        <div className={_className} style={{ ...tStyles, ...styles }}>
                            <Box sx={{ width: '100%', pb: "5px", borderBottom: theme.borderColor }}>
                                <Stack direction="row">
                                    <InputTitle value={pageTitle} onTitleChanged={OnTitleChanged} />
                                    <Grid container sx={{ justifyContent: 'flex-end' }} columnGap={2}>
                                        <Box sx={{ width: 200 }}>
                                            <DropDown size="small" defaContent="Select API"
                                                nameId={'Name'} valueId={'Name'} contentId={'Name'}
                                                name={'dpEntityList'} value={selectedEntity} isEntity={true}
                                                options={filteredEntities} onDropDownChange={OnEntitySelected} />
                                        </Box>
                                    </Grid>
                                </Stack>
                            </Box>

                            {children ? children : <div style={{ minHeight: "calc(100vh - 200px)" }}>&nbsp;</div>}
                            <Box sx={{ width: '100%', bottom: 0, right: 0, mr: 1, mt: 1 }}>
                                <Divider sx={{ marginLeft: 2, marginTop: 1, marginBottom: 1 }} />
                                <Stack spacing={1} direction="row" sx={{ justifyContent: "flex-end" }}>
                                    {stepper && (
                                        <>
                                            <Button variant="contained">Prev</Button>
                                            <Button variant="contained">Next</Button>
                                        </>
                                    )}
                                    {enableSave && <Button variant="outlined" onClick={OnSaveClicked}>Save</Button>}
                                    <Button variant="contained" onClick={OnUpdateClicked}>Return to Code-Wizard</Button>
                                </Stack>
                            </Box>
                        </div>

                    </>
                ) : (
                    <>
                        <div className={_className} style={{ ...tStyles, ...styles }}>
                            {children}
                        </div>
                    </>
                )}
                <div className="product-tabs" style={{
                    display: "flex", flexGrow: 1,
                    marginBottom: "5px",
                    borderLeft: "1px",
                    borderLeftStyle: "solid",
                    borderLeftColor: theme.borderColor
                }}>
                    {screens && screens.length > 0 && (
                        <>
                            <Box style={{
                                padding: "5px 10px 0px 10px",
                                backgroundColor: theme.name === "light" ? "#f5f5f5" : "#252631",
                            }}>
                                <Box>
                                    <Typography noWrap variant="cardheader" component="div">
                                        Selected Screens
                                    </Typography>
                                    <Box sx={{ pb: 1 }}>
                                        {screens && screens.map((screen) => (
                                            <ThumbNail key={screen.index} TeScreenId={screen.TeScreenId} index={screen.index}
                                                url={screen.RouteLink} onScreenListModified={OnScreenListModified} folderLevel={folderLevel}
                                                img={screen.Attribute1} title={screen.ScreenLayout} />
                                        ))}
                                    </Box>
                                </Box>
                            </Box>

                        </>
                    )}
                </div>
            </div>

            <RenamePopup entity={selectedScreen} openDialog={openDialog} setOpenDialog={setOpenDialog} onPropertySelected={OnScreenNameChanged} />

        </>
    )

}

export default Component;
