import React from 'react';
import { IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';


const Component = ({ src, alt, sx, onClick }) => {

    const OnEditClicked = (e) => {
        e.preventDefault();
        if (onClick) onClick(e);
    }

    return (
        <IconButton size="small" onClick={OnEditClicked} sx={{
            p: "5px", ml: "5px", width: 18, height: 18, backgroundColor: "#cccccc",
            "&:hover": {
                backgroundColor: "#9e9e9e"
            },
            ...sx
        }}>
            <EditIcon sx={{ color: "#616161", width: 16, height: 16, mr: 0 }} />
        </IconButton>
    );

};

export default Component;