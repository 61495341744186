import { GetAppInfoApi, GetDocumentsApi } from "./services";
import Session from "./session";

const ParseValue = (e) => {
    if (!isNaN(e)) return parseInt(e);
    if (e.toLowerCase() === 'true') return true;
    if (e.toLowerCase() === 'false') return false;
    return e;

}

const GetQueryParams = () => {
    const params = {};
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    for (const [key, value] of urlParams) params[key] = ParseValue(value);
    return params;
}

const GetPropertyRef = (type) => {
    const childrens = Object.values(type.children) || [];
    const keyItem = childrens.find((x) => x.nodeName === 'Key') || null;
    if (keyItem) {
        const propertyRef = keyItem.getElementsByTagName('PropertyRef') || [];
        if (propertyRef.length > 0) {
            return propertyRef[0].getAttribute('Name');
        }
    }
    return null;
}

const ExtractNavPropery = (x) => {
    let value = x.attributes.Type?.nodeValue.replace(/(^.*\(|\).*$)/g, '') || undefined;
    if (value) value = value.substring(value.indexOf(".") + 1);
    return value;
}

const IsEnumField = (x) => {
    let value = x.attributes.Type?.nodeValue.replace(/(^.*\(|\).*$)/g, '') || false;
    if (value) {
        value = value.substring(0, value.indexOf(".")) !== "Edm";
    }
    return value;
}

const ExtractXml = async (xml) => {
    return new Promise(async (resolve) => {
        const entityTypes = xml.getElementsByTagName("EntityType");
        let obj = [];
        for (let type of entityTypes) {
            const entity = { Name: type.getAttribute("Name"), Properties: [], HasStream: type.getAttribute("HasStream") === 'false' ? false : true };
            entity.PropertyRef = GetPropertyRef(type);
            let props = [];
            const childrens = Object.values(type.children);

            let index = -1;
            for (let prop of ['Property', 'NavigationProperty']) {
                const properties = childrens.filter((x) => x.nodeName === prop);
                const propList = [];
                for (let cIndex in properties) {
                    index++;
                    propList.push({
                        Index: index,
                        Name: properties[cIndex].attributes.Name.nodeValue,
                        Type: ExtractNavPropery(properties[cIndex]),
                        IsNavigate: prop === 'NavigationProperty',
                        IsEnumType: prop === 'NavigationProperty' ? false : IsEnumField(properties[cIndex]),
                        IsCollection: properties[cIndex].attributes.Type.nodeValue.indexOf("Collection") > -1
                    });
                }

                props = [...props, ...propList];

            }
            entity.HasNavigate = props.filter((x) => x.IsNavigate).length > 0;
            entity.Properties = props;
            obj.push(entity);
        }

        for (let i = 0; i < obj.length; i++) {
            const properties = obj[i].Properties;
            for (let pIndex = 0; pIndex < properties.length; pIndex++) {
                let { HasStream } = obj.find((x) => x.Name === properties[pIndex].Type) || false;
                obj[i].Properties[pIndex]['HasStream'] = HasStream;
            }
        }

        return resolve(obj);
    });
}

const CloneObject = (x) => {
    return JSON.parse(JSON.stringify(x));
}

const MergetNavProperites = async (entities) => {
    return new Promise(async (resolve) => {
        let nEntities = [];
        let props;

        for (let i = 0; i < entities.length; i++) {
            let cur = CloneObject(entities[i]);

            let tmpItems = [];
            props = cur.Properties;

            props.filter(m => !m.IsNavigate).forEach((z, index) => {
                let tmp = {
                    Name: z.Name,
                    FieldDesc: z.Name,
                    Label: z.Name,
                    Index: index,
                    EntityType: cur.Name,
                    FieldType: "Property",
                    Type: cur.Name,
                    TypeName: z.Type,
                    NavFieldName: null,
                    DisplayName: z.Name,
                    IsEnumType: z.IsEnumType,
                    HasStream: z.HasStream || false,
                    IsCollection: z.IsCollection || false
                }
                tmpItems.push(tmp);
            });

            if (cur.HasNavigate) {
                let curNavProps = cur.Properties.filter(x => x.IsNavigate).map(z => { return { Type: z.Type, Name: z.Name, IsCollection: z.IsCollection } })
                for (let j = 0; j < curNavProps.length; j++) {
                    const pProp = curNavProps[j];
                    let newProps = CloneObject(entities.filter(x => x.Name === pProp.Type));

                    for (let k = 0; k < newProps.length; k++) {
                        const _props = newProps[k].Properties;
                        let _count = _props.length;
                        if (newProps[k].HasStream) _count = 1;

                        for (let m = 0; m < _count; m++) {

                            let tmp = {
                                Name: pProp.Name,
                                FieldDesc: pProp.Name,
                                Label: pProp.Name,
                                EntityType: cur.Name,
                                FieldType: "NavigationProperty",
                                Type: newProps[k].Name,
                                NavFieldName: _props[m].Name,
                                //DisplayName: `${pProp.Name}/${_props[m].Name}`,
                                DisplayName: pProp.IsCollection ? `${pProp.Name} (${pProp.Type})` : `${pProp.Name} (${pProp.Type}/${_props[m].Name})`,
                                //DisplayName: pProp.Name,
                                HasStream: newProps[k].HasStream || false,
                                IsCollection: pProp.IsCollection || false
                            }
                            tmpItems.push(tmp);

                        }

                        //tmpItems = tmpItems.filter(x => x.DisplayName !== pProp.Name);
                        tmpItems.forEach((x, index) => x.Index = index);
                    }
                }
            }

            cur.Properties = tmpItems;
            nEntities.push(cur);
        }

        return resolve(nEntities);

    });
}

const GetEntities = async (appId) => {
    return new Promise(async (resolve) => {
        await GetAppInfoApi(appId)
            .then(async (res) => {
                if (res && res.AppConfigurationXMLModel) {
                    await GetDocumentsApi(res.AppConfigurationXMLModel)
                        .then(async (doc) => {
                            if (doc) {
                                const xmlPattern = /<\?xml.*?\?>[\s\S]*?<edmx:Edmx.*?>[\s\S]*?<\/edmx:Edmx>/;
                                const xmlMatch = doc.match(xmlPattern);
                                const xmlContent = xmlMatch[0];
                                const parser = new DOMParser();
                                const xmlDoc = parser.parseFromString(xmlContent, "application/xml");
                                let entities = await ExtractXml(xmlDoc);
                                entities = await MergetNavProperites(entities);
                                return resolve(entities);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            return resolve([]);
                        })
                }
            })
            .catch((err) => {
                console.log(err);
                return resolve([]);
            })
    });
}

const UpdateSession = async (input) => {
    return new Promise(async (resolve) => {
        let collection = await Session.RetrieveAsync('collection', true) || [];
        let index = collection.findIndex((x) =>
            x.EntityTypeName === input.EntityTypeName &&
            parseInt(x.screenIndex) === parseInt(input.screenIndex) &&
            parseInt(x.FrontendScreenTemplateScreen) === parseInt(input.FrontendScreenTemplateScreen));
        if (index > -1) {
            collection[index] = input;
        } else {
            collection.push(input);
        }
        await Session.StoreAsync('collection', collection, true);
        return resolve(true);
    });
}

const GetStoredColumnsFromSession = async (input) => {
    return new Promise(async (resolve) => {
        let properties = {};
        let collection = await Session.RetrieveAsync('collection', true) || [];
        let index = collection.findIndex((x) => parseInt(x.screenIndex) === parseInt(input.screenIndex)
            && parseInt(x.FrontendScreenTemplateScreen) === parseInt(input.FrontendScreenTemplateScreen));

        if (input.entityName) {
            index = collection.findIndex((x) => parseInt(x.screenIndex) === parseInt(input.screenIndex) && x.EntityTypeName === input.entityName
                && parseInt(x.FrontendScreenTemplateScreen) === parseInt(input.FrontendScreenTemplateScreen));
        }
        if (index > -1) {
            properties = collection[index].properties;
        }

        return resolve(properties);
    });
}

export {
    GetQueryParams, GetEntities, UpdateSession,
    GetStoredColumnsFromSession
};