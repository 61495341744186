var fn = {};

fn.IsNull = (e) => {
    if (e === undefined || e === null) return true;
    return false;
};

fn.IsNullValue = (e) => {
    if (e === undefined || e === null || e === "") return true;
    return false;
};

fn.ToBool = (e, defa) => {
    if (fn.IsNullValue(e)) return false;
    if (e === "true" || e === true) return true;
    if (e === "false" || e === false) return false;
};

fn.IsJSONEmpty = (e) => {
    if (fn.IsNull(e)) return true;
    for (var key in e) {
        if (Object.prototype.hasOwnProperty.call(e, key)) {
            return false;
        }
    }
    return true;
};

fn.ToString = (e, defa) => {
    if (fn.IsNull(e)) return defa ? defa : "";
    return e.toString();
};

fn.ReplaceAll = (e, f, r) => {
    if (fn.IsNullValue(e)) return e;
    const regX = new RegExp(f, 'ig');
    return e.replace(regX, r);
}

fn.RemoveDuplicates = (arr, field) => {
    var cleaned = [];
    arr.forEach((item) => {
        let _name, index;
        if (field) {
            _name = item[field];
            index = cleaned.findIndex((x) => x[field] === _name);
            if (index === -1) cleaned.push(item);
        } else {
            _name = item;
            index = cleaned.findIndex((x) => x === _name);
            if (index === -1) cleaned.push(item);
        }
    });

    return cleaned;
}

fn.GetUrlQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    let tmp = [];
    ['selectedScreenIds', 'templateId', 'cAppId'].forEach(x => tmp.push(`${x}=${params.get(x)}`));
    return tmp.join("&");
}

fn.GetQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    return Object.fromEntries(params);
}

fn.FindFirst = (e) => {
    if (fn.IsJSONEmpty(e)) return null;
    const _array = Object.values(e);
    if (_array.length > 0) return _array[0];
    return null;
}

fn.CloneObject = (x) => {
    return JSON.parse(JSON.stringify(x));
}

fn.TimeStamp = () => { return new Date().valueOf(); }

export default fn;