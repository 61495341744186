import React from 'react';
import { Box } from '@mui/material';


const Component = ({ src, alt, sx, onClick }) => {

    return (
        <Box
            component="img"
            sx={{ ...sx }}
            alt={alt}
            src={src}
            onClick={() => onClick && onClick()}
        />
    );

};

export default Component;