import React from "react";
import { Routes, Route } from "react-router-dom";
import { ProductView, ProductConfigure, ProductEdit, ProductList, ProductTiles, OneToMany } from "screens/product";
import DashBoard from "screens/dashboard";
import { StepperForm, FullForm } from "screens/productstepper";

const Component = (props) => {

    return (
        <Routes>
            <Route path="/products/view" element={<ProductView {...props} title={'Product Table View'} />} />
            <Route path="/products/edit" element={<ProductEdit {...props} title={'Product Table Edit'} />} />
            <Route path="/products/list" element={<ProductList {...props} title={'Product Table List'} />} />
            <Route path="/products/tiles" element={<ProductTiles {...props} title={'Product Table List'} />} />
            <Route path="/product_detail" element={<ProductView {...props} title={'Product Table View'} />} />
            <Route path="/product_many" element={<OneToMany {...props} title={'Product One to many'} />} />
            <Route path="/products" element={<ProductConfigure {...props} title={'Product Table'} />} />
            <Route path="/stepper" element={<StepperForm {...props} title={'Stepper Form'} />} />
            <Route path="/infoform" element={<FullForm {...props} title={'Full Form'} />} />
            <Route path="/:test=temp" element={<ProductConfigure {...props} title={'Products Table'} nolistbar={true} />} />
            <Route path="/" element={<DashBoard {...props} title={'Dashboard'} nolistbar={true} />} />
        </Routes>
    )

};

export default Component;