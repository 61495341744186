import React from "react";
import { useTheme } from '@mui/material/styles';
import { MenuItem, Select } from "@mui/material";

const Component = (props) => {
    const { name, value, nameId, valueId, contentId, defaContent, isEntity,
        options, size, onDropDownChange } = props;
    const theme = useTheme();
    const [selectedValue, setSelectedValue] = React.useState(value || 'NONE');

    let defValues = [{ id: -1, value: 'NONE', disabled: isEntity ? false : true, content: defaContent || 'Select a column' }];

    const OnChange = (e) => {
        e.stopPropagation();
        setSelectedValue(e.target.value);
        if (onDropDownChange) onDropDownChange(e);
    }

    React.useEffect(() => {
        let tmp = value || "NONE";
        setSelectedValue(tmp);
    }, [value]);

    return (
        <>
            <Select name={name} value={selectedValue || "NONE"} size={size}
                onChange={(e) => OnChange(e)}
                onOpen={(e) => e.stopPropagation()}
                onBlur={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                MenuProps={{
                    sx: {
                        "&& .Mui-selected": { ...theme.selected }
                    }
                }}
                sx={{ width: "100%" }}
            >

                {defValues.map((x, index) => (
                    <MenuItem key={1000 + index} disabled={x.disabled} value={x.value} name={x.name}>
                        {x.content}
                    </MenuItem>
                ))}

                {options && options.map((x, index) => (
                    <MenuItem key={2000 + index} value={x[valueId]} name={x[nameId]}>
                        {x[contentId]}
                    </MenuItem>
                ))}

            </Select>
        </>
    )
}

export default Component;