import React from "react";
import {
    Box, Dialog, Tooltip, Grid, DialogTitle, DialogContent,
    DialogActions, Button, TextField
} from "@mui/material";
import { InputAdornment, IconButton } from '@mui/material';
import { FindInPage } from '@mui/icons-material';
import Helper from "shared/helper";
import { SetDocument } from "shared/services";
import Session from "shared/session";

const acceptTypes = ".JPG,.JPEG,.PNG,.ICO";

const DisplayImage = ({ src, alt, sx, onClick, ...rest }) => {

    return (
        <Tooltip title={alt} followCursor>
            <Box
                {...rest}
                component="img"
                sx={{ ...sx }}
                alt={alt}
                src={src}
                onClick={() => onClick && onClick()}
            />
        </Tooltip>
    );

};

const defaultError = "Something went wrong!";

const Component = (props) => {
    const { onDialogClosed } = props;
    const [error, setError] = React.useState(null);
    const [inputValue, setInputValue] = React.useState("");
    const [imageData, setImageData] = React.useState(null);
    const [imageInfo, setImageInfo] = React.useState(null);
    let fileRef = React.createRef();

    const OnButtonClicked = () => {
        if (onDialogClosed) onDialogClosed();
    }

    const OnFileInputChanged = (e) => {
        e.preventDefault();
        setError(null);
        let _file = e.target.files[0];
        if (!Helper.IsNullValue(_file)) {
            let _ext = _file.name.split(".").pop();
            let _index = acceptTypes.toUpperCase().split(",").findIndex((x) => x === `.${_ext.toUpperCase()}`);
            if (_index === -1) {
                setInputValue(null);
                // eslint-disable-next-line
                let tmp = `Supported Format: ${acceptTypes.toUpperCase().replace(/\./g, "").replace(/\,/g, ", ")}`;
                setError(tmp);
            } else {
                ReadDocument(_file);
            }
        }
    }

    const UploadDocument = async (e) => {
        e.preventDefault();
        let docId = 0;
        global.Busy(true);
        const { DocType, DocExt, DocName, DocData } = imageInfo;
        let rslt = await SetDocument(DocData, { DocType, DocExt, DocName });
        global.Busy(false);
        if (rslt.status) {
            docId = rslt.id;
            Session.StoreAsync("FrontendAppLogo", docId);
            if (onDialogClosed) onDialogClosed(DocData);
        } else {
            const msg = rslt.statusText || defaultError;
            setError(msg);
        }
    };

    const ReadDocument = (input) => {
        var reader = new FileReader();
        reader.onload = (e) => {
            const DocData = e.target.result;
            setImageData(DocData);
            setInputValue(input.name);

            const DocName = input.name.split(".")[0];
            const DocType = input.type;
            const DocExt = input.name.split(".").pop().toUpperCase();

            setImageInfo({ DocName, DocType, DocExt, DocData });

        };
        reader.readAsDataURL(input);
    }

    const OnFileBrowseClicked = () => {
        setError(null);
        fileRef.click();
    }

    return (
        <>
            <Dialog open={true} style={{ top: -100 }}>
                <DialogTitle sx={{ m: 0, p: 2 }} style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ whiteSpace: "nowrap", paddingTop: 10 }}>Select Company Logo</div>
                </DialogTitle>
                <DialogContent dividers>

                    <Grid container spacing={2} columns={16}>
                        <Grid item>
                            <TextField size="small" disabled InputLabelProps={{ shrink: false }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        paddingLeft: "1px"
                                    },
                                    "& label": {
                                        display: "none"
                                    }
                                }}
                                value={inputValue || ""}
                                style={{ minWidth: 250 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                variant="contained"
                                                size="small"
                                                edge="start"
                                                aria-label="filebrowse"
                                                onClick={OnFileBrowseClicked}>
                                                <FindInPage />
                                                <input type="file" hidden accept={acceptTypes} onChange={OnFileInputChanged}
                                                    ref={input => fileRef = input} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        {imageData && (
                            <Grid item sx={{
                                padding: "0px !important",
                                paddingLeft: "2px !important"
                            }}>
                                <DisplayImage borderRadius="4px" sx={{ width: 40, border: '1px solid #ddd', p: 1, mt: 2 }}
                                    alt={"Company Logo"} src={imageData} />
                            </Grid>
                        )}
                    </Grid>
                    {error && <div>{error}</div>}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={UploadDocument}>Upload</Button>
                    <Button variant="outlined" onClick={() => OnButtonClicked(true)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Component;