import React, { useEffect, useState } from "react";
import { Box } from '@mui/material';
import Container from "screens/container";
import ProductJson from "../sample.json";
import PropertySelectList from "screens/propertySelect";
import { DataGrid } from '@mui/x-data-grid';
import { UpdateSession, GetStoredColumnsFromSession } from "shared/common";
import Helper from "shared/helper";

const screenDescription = "Table Layout to display Buses in rows. One can perform different operations on Buses";

const Component = (props) => {
    const { screens } = props;

    const [jsonData, setJsonData] = useState([]);
    const [columnItems, setColumnItems] = useState([]);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState({});
    const [state, setState] = React.useState(false);
    const [clearState, setClearState] = React.useState(false);
    const [loadEntity, setLoadEntity] = React.useState(false);
    const [preLoaded, setPreLoaded] = React.useState(false);
    const [loadColumns, setLoadColumns] = React.useState(false);
    const { screenId, screenIndex } = Helper.GetQueryParams();

    const OnEntitySelected = async (e) => {
        const _nonCollections = e?.filter(x => !x.IsCollection) || [];
        setSelectedProperties(_nonCollections);
    }

    const onDropDownSelected = (e) => {
        let lastColumns = selectedColumns || {};
        const { options, value, name, seqNumber } = e;
        if (value === 'NONE') {
            delete lastColumns[name];
        } else {
            let _field = options.find(x => x.DisplayName === value);
            _field = { ..._field, seqNumber };
            lastColumns[name] = _field;
        }
        setSelectedColumns(lastColumns);
        setState(!state);
    }

    const GetSelectedColumnValue = (e) => {
        let rtnVal = "NONE";
        if (!Helper.IsNullValue(selectedColumns) && !Helper.IsJSONEmpty(selectedColumns[e])) {
            rtnVal = selectedColumns[e].DisplayName || "NONE";
        }
        return rtnVal;
    }

    const RenderColumns = async (properties) => {
        let _columnItems = [], rowData = [], defaCols = [];
        const props = properties.slice(0, 6);

        for (let col in ProductJson[0]) defaCols.push(col);

        for (let i = 0; i < props.length; i++) {
            let prop = props[i];
            let keyId = `${prop.Name}_${prop.Index}`;
            let tValue = GetSelectedColumnValue(keyId);
            if (tValue === 'NONE') tValue = selectedProperties[i].Name;
            prop.Value = tValue;
        }

        setPreLoaded(true);

        for (let iNum = 0; iNum < props.length; iNum++) {
            let property = props[iNum];
            _columnItems.push({
                field: `${property.Name}_${property.Index}`, headerName: property.Name, flex: 1,
                renderHeader: () => (
                    <>
                        <PropertySelectList keyId={`${property.Name}_${property.Index}`} selectedColumns={selectedColumns}
                            nameId={'DisplayName'} valueId={'DisplayName'} value={property.Value} fieldSequenceNumber={iNum + 1}
                            options={properties} onDropDownChange={onDropDownSelected} />
                    </>
                )
            });
        }

        ProductJson.forEach((z) => {
            const _row = {};
            _row['id'] = z.id;
            properties.forEach((x, k) => {
                _row[`${x.Name}_${x.Index}`] = z[defaCols[k]];
            });
            rowData.push(_row);
        })

        setJsonData(rowData);
        setColumnItems(_columnItems);
        setState(!state);
    }

    const OnSaveClicked = async (e) => {
        if (!Helper.IsNullValue(selectedColumns) && !Helper.IsJSONEmpty(selectedColumns)) {
            const _screen = screens.find(x => parseInt(x.TeScreenId) === parseInt(screenId) && parseInt(x.index) === parseInt(screenIndex));
            let data = {
                ParentEntityType: Helper.FindFirst(selectedColumns)['EntityType'],
                EntityTypeName: Helper.FindFirst(selectedColumns)['EntityType'],
                FrontendScreenTemplateScreen: screenId,
                ScreenDescription: screenDescription,
                ScreenLayout: _screen.ScreenLayout,
                properties: selectedColumns,
                screenIndex
            };
            UpdateSession(data);
            Reset(true);
        }
    }

    const Reset = (all) => {
        setColumnItems([])
        setSelectedColumns({});
        if (all) {
            setClearState(!clearState);
            setSelectedProperties([]);
            setPreLoaded(false);
        }
        setState(!state);
    }

    const LoadDetails = async () => {
        Reset();
        if (selectedProperties !== null && selectedProperties.length > 0) {

            const props = selectedProperties.slice(0, 6);

            let sessionColumns = await GetStoredColumnsFromSession(
                { FrontendScreenTemplateScreen: screenId, screenIndex }
            );

            if (Helper.IsJSONEmpty(sessionColumns)) {
                sessionColumns = {};

                for (let iNum = 0; iNum < props.length; iNum++) {
                    let property = props[iNum];
                    sessionColumns = { ...sessionColumns, [`${property.Name}_${property.Index}`]: { seqNumber: iNum + 1, ...property } };
                }
            }

            setSelectedColumns(sessionColumns);
            setState(!state);
            setLoadColumns(true);
        }
    }

    if (loadColumns) {
        setLoadColumns(false);
        RenderColumns(selectedProperties);
    }

    if (loadEntity) {
        setLoadEntity(false);
        LoadDetails();
    }

    useEffect(() => { setLoadEntity(true); }, [selectedProperties]);

    return (
        <>
            <Container {...props} clear={clearState} onEntitySelected={OnEntitySelected} onSaveClicked={OnSaveClicked}
                enableSave={!Helper.IsJSONEmpty(selectedColumns) || preLoaded}>
                {columnItems && columnItems.length > 0 && (
                    <>
                        <Box style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                disableColumnMenu
                                rows={jsonData}
                                columns={columnItems}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                            />
                        </Box>
                    </>
                )}
            </Container>
        </>
    );

};

export default Component;