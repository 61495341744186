import React from "react";
import {
    Typography, Dialog, TextField, Select, MenuItem, DialogTitle, DialogContent,
    DialogActions, Button
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import * as MuiIcons from '@mui/icons-material';
import Helper from "shared/helper";
import ScreenUpdate from "shared/screen";
import { DEFAULT_ICON } from "config";


const iconNames = [DEFAULT_ICON, 'ShoppingBasket', 'Toc', 'EditNote', 'Checklist', 'Tab', 'GridView'];

const CustomDropDown = (props) => {
    const { name, value, options, onDropDownChange } = props;
    const theme = useTheme();
    const [selectedValue, setSelectedValue] = React.useState(value || -1);

    const OnChange = (e) => {
        e.stopPropagation();
        let _option = { index: -1, ScreenURL: null };
        const value = e.target.value;
        setSelectedValue(value);
        if (parseInt(value) !== -1) {
            _option = options.find((x) => parseInt(x.index) === parseInt(value));
        }
        if (onDropDownChange) onDropDownChange(_option);
    }

    React.useEffect(() => {
        let tmp = -1;
        if (!Helper.IsNullValue(value)) tmp = parseInt(value);
        setSelectedValue(tmp);
    }, [value]);

    return (
        <>
            <Select name={name} value={selectedValue} size="small"
                onChange={(e) => OnChange(e)}
                onOpen={(e) => e.stopPropagation()}
                onBlur={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                MenuProps={{
                    sx: {
                        "&& .Mui-selected": { ...theme.selected }
                    }
                }}
                sx={{ width: "100%" }}
            >
                <MenuItem value={-1}>Select Screen</MenuItem>
                {options && options.map((x, index) => (
                    <MenuItem key={1000 + index} value={x.index}>{x.ScreenLayout}</MenuItem>
                ))}

            </Select>
        </>
    )
}

const GenerateIcon = (variation) => {
    const IconName = MuiIcons[variation];
    const iconSize = { width: 24, height: 24, mr: "10px" };
    return <IconName sx={{ ...iconSize }} />
};

const Component = (props) => {
    const { screens, setOpenDialog, openDialog } = props;

    const [screenUrl, setScreenUrl] = React.useState("");
    const [screenIcon, setScreenIcon] = React.useState(iconNames[0]);
    const [selectedScreen, setSelectedScreen] = React.useState({ index: -1 });
    const [error, setError] = React.useState(null);
    const [state, setState] = React.useState(false);

    const ResetValues = async () => {
        return new Promise(resolve => {
            setSelectedScreen({ index: -1 });
            setScreenUrl("");
            setError("");
            return resolve(true);
        });
    };

    const OnDropDownChanged = (e) => {
        setSelectedScreen(e);
        setScreenUrl(e.ScreenURL);
        if (e.icon) setScreenIcon(e.icon);
        setState(!state);
    }

    const OnButtonClicked = async (e, bClose) => {
        e.preventDefault();
        if (!bClose) {
            if (Helper.IsNullValue(screenUrl)) {
                setError("Enter screen name");
                return;
            }
            let _index = screens.findIndex((x) => x.ScreenURL.toLowerCase() === screenUrl.toLowerCase());
            if (_index > -1) {
                setError("Enter different screen url");
                return;
            }

            _index = screens.findIndex((x) => parseInt(x.index) === parseInt(selectedScreen.index));
            screens[_index].ScreenURL = screenUrl;
            screens[_index].IsNavigable = true;
            screens[_index].icon = screenIcon;
            setState(!state);

            ScreenUpdate.Update(screens);
        }

        await ResetValues();
        setOpenDialog(false);
    }

    const GetScreenDropDownList = () => {
        return (screens && screens.filter(z => z.IsNavigable)) || [];
    }

    return (
        <>
            <Dialog open={openDialog} style={{ top: -100 }}>
                <DialogTitle sx={{ m: 0, p: 2 }} style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", whiteSpace: "nowrap", justifyContent: "center", alignSelf: "center" }}>Screen Details</div>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="navPopupBodyRow" style={{ marginTop: 1 }}>
                        <CustomDropDown options={GetScreenDropDownList()} onDropDownChange={OnDropDownChanged} value={selectedScreen?.index} />
                        {/* <DropDown size="small" defaContent="Select Screen"
                            nameId={'index'} valueId={'index'} contentId={'ScreenLayout'}
                            name={'screenList'} value={selectedScreenId}
                            options={screens} onDropDownChange={OnDropDownChanged} /> */}
                    </div>
                    <div className="navPopupBodyRow" style={{ display: "flex", flexDirection: "column" }}>
                        <Typography className="navPopupBodyColumnLabelLeft">Screen URL</Typography>
                        <div className="navPopupBodyColumnText" style={{ width: "100%" }}>
                            <TextField size="small" sx={{ width: "100%" }}
                                value={Helper.ToString(screenUrl)} onChange={(e) => setScreenUrl(e.target.value)} />
                        </div>
                    </div>
                    <div className="navPopupBodyRow" style={{ display: "flex", flexDirection: "column" }}>
                        <Typography className="navPopupBodyColumnLabelLeft">Menu Icon</Typography>
                        <div className="navPopupBodyColumnText" style={{ width: "100%" }}>
                            <Select style={{ width: "100%" }}
                                value={screenIcon}
                                onOpen={(e) => e.stopPropagation()}
                                onBlur={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()}
                                onKeyDown={(e) => e.stopPropagation()}
                                onChange={(e) => setScreenIcon(e.target.value)}>
                                {iconNames.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {GenerateIcon(name)}{name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    {error && <Typography sx={{ width: "100%", color: "red", textAlign: "center" }}>{error}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus onClick={(e) => OnButtonClicked(e, false)}>Ok</Button>
                    <Button variant="outlined" onClick={(e) => OnButtonClicked(e, true)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Component;