import Session from "./session";
import Helper from "./helper";

var fn = {};

const GetScreensList = () => {
    return Session.Retrieve("screens", true);
}

const ChangeFlag = () => {
    const flag = Helper.ToBool(Session.Retrieve('screenmodified'));
    Session.Store('screenmodified', !flag);
}

fn.Update = (e) => {
    Session.StoreAsync("screens", e, true);
    ChangeFlag();
}

fn.Rename = (e) => {
    let screensList = GetScreensList();
    let curIndex = screensList.findIndex((x) => parseInt(x.TeScreenId) === parseInt(e.TeScreenId) && parseInt(x.index) === parseInt(e.index));
    if (curIndex > -1) {
        screensList[curIndex].ScreenLayout = e.ScreenLayout;
        Session.StoreAsync("screens", screensList, true);
        ChangeFlag();
    }
}

fn.Delete = (e) => {
    let screensList = GetScreensList();
    screensList = screensList.filter((x) => parseInt(x.index) !== parseInt(e.index));
    Session.StoreAsync("screens", screensList, true);
    ChangeFlag();
}

fn.DuplicateScreen = (e) => {
    const screensList = GetScreensList();
    let _newItem = {};
    let curIndex = screensList.findIndex((x) => parseInt(x.TeScreenId) === parseInt(e.TeScreenId) && parseInt(x.index) === parseInt(e.index));
    if (curIndex === -1) return;
    const _length = screensList.filter((x) => x.TeScreenId === e.TeScreenId).length;
    let _selectedItem = screensList[curIndex];
    for (let p of Object.keys(_selectedItem)) {
        _newItem = { ..._newItem, [p]: _selectedItem[p] };
    }
    _newItem.index = screensList.length;
    _newItem.ScreenLayout = `${_newItem.ScreenLayout} ${_length}`;
    screensList.splice(curIndex + 1, 0, _newItem);
    Session.StoreAsync("screens", screensList, true);
    ChangeFlag();
}

export default fn;