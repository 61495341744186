import { Box, TableContainer, Table, TableBody, TableCell, TableRow, Paper, Typography } from '@mui/material';

const Component = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <TableContainer align="left" component={Paper} sx={{ width: "auto", boxShadow: 3 }} >
                <Table align="left" sx={{ display: 'table', border: 0 }}>
                    <TableBody>
                        <TableRow>
                            <TableCell align="right" sx={{ paddingTop: 3, border: 0, whiteSpace: "nowrap" }}>
                                <Typography nowrap="true" variant="labelheader">Product Type</Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ paddingTop: 3, border: 0, whiteSpace: "nowrap", minWidth: 250 }}>
                                <Typography nowrap="true">Laptop</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" sx={{ paddingTop: 1, border: 0, whiteSpace: "nowrap" }}>
                                <Typography nowrap="true" variant="labelheader">Product Description</Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ paddingTop: 1, border: 0, whiteSpace: "nowrap" }}>
                                <Typography nowrap="true">Dell Latitude 5420</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box >
    );

};


export default Component;