import React from 'react';
import { Button, Stack, Box, Divider, Typography, Avatar } from '@mui/material';
import Container from "screens/container";
import SampleComponent from "./sample";
import Helper from "shared/helper";
import PropertySelectList from "screens/propertySelect";
import { UpdateSession, GetStoredColumnsFromSession } from "shared/common";

const screenDescription = "Stepper Layout to display Buses in rows. One can perform different operations on Buses";

const RenderWorkSpace = (props) => {
    const { screens, properties } = props;
    const [loadEntity, setLoadEntity] = React.useState(false);
    const [selectedProperties, setSelectedProperties] = React.useState([]);
    const [maxItems, setMaxItems] = React.useState(0);
    const [selectedColumns, setSelectedColumns] = React.useState({});
    const [state, setState] = React.useState(false);
    const { screenId, screenIndex } = Helper.GetQueryParams();

    const onDropDownSelected = (e) => {
        let lastColumns = selectedColumns || {};
        const { options, value, name } = e;
        if (value === 'NONE') {
            delete lastColumns[name];
        } else {
            const _field = options.find(x => x.DisplayName === value);
            lastColumns[name] = _field;
        }
        setSelectedColumns(lastColumns);
        setState(!state);
    }

    const GetSelectedColumnValue = (e) => {
        let rtnVal = "NONE";
        if (!Helper.IsNullValue(e.sessionColumns) && !Helper.IsJSONEmpty(e.sessionColumns[e.id])) {
            rtnVal = e.sessionColumns[e.id].DisplayName || "NONE";
        }
        return rtnVal;
    }

    const LoadDetails = async (props) => {
        setSelectedColumns({});
        setSelectedProperties([]);
        setMaxItems([]);
        if (props !== null && props.length > 0) {
            let sessionColumns = await GetStoredColumnsFromSession(
                { FrontendScreenTemplateScreen: screenId, screenIndex }
            );
            setSelectedColumns(sessionColumns);
            setState(!state);

            props.forEach(x => {
                x.Value = GetSelectedColumnValue({ sessionColumns, id: `${x.Name}_${x.Index}` })
            });

            const count = props.length > 6 ? 5 : props.length;
            setSelectedProperties(props);
            setMaxItems(count);
            setState(!state);
        }
    }

    const OnSaveClicked = async (e) => {
        e.preventDefault();
        if (!Helper.IsNullValue(selectedColumns) && !Helper.IsJSONEmpty(selectedColumns)) {
            const _screen = screens.find(x => parseInt(x.TeScreenId) === parseInt(screenId) && parseInt(x.index) === parseInt(screenIndex));
            let data = {
                ParentEntityType: Helper.FindFirst(selectedColumns)['EntityType'],
                EntityTypeName: Helper.FindFirst(selectedColumns)['EntityType'],
                FrontendScreenTemplateScreen: screenId,
                ScreenDescription: screenDescription,
                ScreenLayout: _screen.ScreenLayout,
                properties: selectedColumns,
                screenIndex
            };
            UpdateSession(data);
            setSelectedColumns({});
        }
    }

    if (loadEntity) {
        setLoadEntity(false);
        LoadDetails(properties);
    }

    React.useEffect(() => { setLoadEntity(true); }, [properties]);

    return (
        <>
            <Box style={{ display: 'flex', paddingLeft: "8px", width: '100%', flexDirection: "column" }}>
                {maxItems > 0 && Array.from({ length: maxItems }).map((_, i) => {
                    const x = selectedProperties[i];
                    return (
                        <Box key={i} sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                            <Box sx={{ width: "25%" }}>
                                <PropertySelectList keyId={`${x.Name}_${x.Index}`} selectedColumns={selectedColumns}
                                    nameId={'DisplayName'} valueId={'DisplayName'} value={x.Value}
                                    options={selectedProperties} onDropDownChange={onDropDownSelected} />
                            </Box>
                            <Box sx={{ display: 'flex', minWidth: "30px", alignItems: 'center' }}>
                                <Typography>{x.value || "Not Available"}</Typography>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
            <Divider sx={{ marginTop: 2 }} />
            <Box sx={{ padding: 1 }}>
                {!Helper.IsJSONEmpty(selectedColumns) && (<Button variant="outlined" onClick={OnSaveClicked}>Save</Button>)}
            </Box>
        </>
    );
}

const Component = (props) => {

    const { entities } = props;

    const [state, setState] = React.useState(false);
    const [loadEntity, setLoadEntity] = React.useState(false);
    const [entityType, setEntityType] = React.useState(null);

    const [selectedSteps, setSelectedSteps] = React.useState({});
    const [selectedProperties, setSelectedProperties] = React.useState(null);
    const [stepOptions, setStepOptions] = React.useState([]);
    const [stepItems, setStepItems] = React.useState([]);

    const OnEntitySelected = async (e) => {
        setEntityType(null);
        if (e) setEntityType(e[0].EntityType);
    }

    const SetProperties = (e) => {
        const _entities = entities.find(x => x.Name === e);
        const _nonCollections = _entities.Properties?.filter(x => !x.IsCollection) || [];
        const _properties = Helper.CloneObject(_nonCollections);
        setSelectedProperties(_properties);
    }

    const onDropDownSelected = (e) => {
        let lastColumns = selectedSteps || {};
        const { options, value, name } = e;
        setSelectedProperties(null);
        if (value === 'NONE') {
            delete lastColumns[name];
            if (!Helper.IsJSONEmpty(lastColumns)) {
                const _columns = Object.values(lastColumns);
                if (_columns.length > 0) SetProperties(_columns[0].name);
            }

        } else {
            const _field = options.find(x => x.id === value);
            lastColumns[name] = _field;
            SetProperties(_field.name);
        }
        setSelectedSteps(lastColumns);
        setState(!state);
    }

    const PrepareForStepper = (items) => {
        setSelectedProperties(null);
        setSelectedSteps({})
        if (items && items.length > 0) {
            const tOptions = items.map((x, index) => { return { id: index + 1, name: x.Name } });
            let _stepItems = items.map((x, index) => {
                return {
                    stepId: index + 1,
                    stepName: `STEP_NAME_${index + 1}`
                }
            });
            const count = _stepItems.length > 6 ? 5 : _stepItems.length;
            _stepItems = _stepItems.splice(0, count);
            setStepItems(_stepItems);
            setStepOptions(tOptions);
        }
    };

    const LoadDetails = async () => {
        let selectedEntities = [];
        if (entityType) {
            let _navEntities = entities.find(x => x.Name === entityType).Properties.map(z => z.Type);
            _navEntities = Helper.RemoveDuplicates(_navEntities);
            let tmp = entities.filter(x => _navEntities.indexOf(x.Name) > -1);
            tmp.forEach(x => selectedEntities.push(Helper.CloneObject(x)));
        }
        PrepareForStepper(selectedEntities);
    }

    if (loadEntity) { setLoadEntity(false); LoadDetails(); }

    React.useEffect(() => { setLoadEntity(true); }, [entityType]);

    return (
        <Container {...props} onEntitySelected={OnEntitySelected} stepper={true}>
            {stepItems && stepItems.length > 0 ? (
                <>
                    <Stack id="stepperList" direction="row">
                        {stepItems.map(x => {
                            return (
                                <Stack key={x.stepId} direction="row" sx={{ marginTop: 1, mr: 1, width: "20%" }} spacing={1} alignItems="center">
                                    <Stack>
                                        <Avatar sx={{ fontSize: "0.8rem", width: 24, height: 24, backgroundColor: "#1976d2" }}>{x.stepId}</Avatar>
                                    </Stack>
                                    <Stack sx={{ width: "100%" }}>
                                        <PropertySelectList keyId={x.stepName} selectedColumns={selectedSteps}
                                            nameId={'name'} valueId={'id'} value={x.Value} onDropDownChange={onDropDownSelected}
                                            options={stepOptions} />
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack>
                    <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
                    {!Helper.IsJSONEmpty(selectedSteps) ? (
                        <RenderWorkSpace {...props} properties={selectedProperties} />
                    ) : (<SampleComponent />)}
                </>
            ) : null}

        </Container>
    )
}

export default Component;