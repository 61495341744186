import React from "react";
import { Box } from '@mui/material';
import Container from "screens/container";

const Component = (props) => {
    return (
        <>
            <Container {...props} landingPage={true} >
                <Box style={{ height: 400, width: '100%', fontWeight: "bold", fontSize: "1.5rem" }}>
                    Select any screen from right hand side to get started
                </Box>
            </Container>
        </>
    );

};

export default Component;