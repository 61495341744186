import React from 'react';
import { Button, Box, Grid, Divider, Typography, IconButton, Stack, Avatar, Tooltip } from '@mui/material';
import Container from "screens/container";
import { useTheme } from '@mui/material/styles';
import Helper from "shared/helper";
import { UpdateSession, GetStoredColumnsFromSession } from "shared/common";
import PropertySelectList from "screens/propertySelect";
import ProductJson from "../sample.json";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Add as AddBoxIcon, Edit as EditIcon, DeleteOutlined as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';

import { SearchInput } from "components";

const screenDescription = "OneToMany Form Layout to display Buses in rows. One can perform different operations on Buses";

const RenderTableWorkSpace = (props) => {
    const { screens, entityType, properties, navigationName, entityName, stepName } = props;
    const [loadEntity, setLoadEntity] = React.useState(false);
    const [selectedColumns, setSelectedColumns] = React.useState({});
    const [state, setState] = React.useState(false);
    const { screenId, screenIndex } = Helper.GetQueryParams();

    const [jsonData, setJsonData] = React.useState([]);
    const [preLoaded, setPreLoaded] = React.useState(false);
    const [columnItems, setColumnItems] = React.useState([]);
    const theme = useTheme();

    const onDropDownSelected = (e) => {
        let lastColumns = selectedColumns || {};
        const { options, value, name, seqNumber } = e;
        if (value === 'NONE') {
            delete lastColumns[name];
        } else {
            let _field = options.find(x => x.DisplayName === value);
            _field = { ..._field, seqNumber };
            lastColumns[name] = _field;
        }
        setSelectedColumns(lastColumns);
        setState(!state);
    }

    const GetSelectedColumnValue = (e) => {
        let rtnVal = "NONE";
        if (!Helper.IsNullValue(e.sessionColumns) && !Helper.IsJSONEmpty(e.sessionColumns[e.id])) {
            rtnVal = e.sessionColumns[e.id].DisplayName || "NONE";
        }
        return rtnVal;
    }

    const RenderColumns = async (properties) => {
        let _columnItems = [], rowData = [], defaCols = [];
        const props = properties.slice(0, 6);

        for (let col in ProductJson[0]) defaCols.push(col);

        for (let i = 0; i < props.length; i++) {
            let prop = props[i];
            let keyId = `${prop.Name}_${prop.Index}_${stepName}`;
            let tValue = GetSelectedColumnValue(keyId);
            if (tValue === 'NONE') tValue = prop.Name;
            prop.Value = tValue;
        }

        setPreLoaded(true);

        for (let iNum = 0; iNum < props.length; iNum++) {
            let property = props[iNum];
            _columnItems.push({
                field: `${property.Name}_${property.Index}`, headerName: property.Name, flex: 1,
                renderHeader: () => (
                    <>
                        <PropertySelectList keyId={`${property.Name}_${property.Index}`} selectedColumns={selectedColumns}
                            nameId={'DisplayName'} valueId={'DisplayName'} value={property.Value} fieldSequenceNumber={iNum + 1}
                            options={properties} onDropDownChange={onDropDownSelected} />
                    </>
                )
            });
        }

        ProductJson.forEach((z) => {
            const _row = {};
            _row['id'] = z.id;
            properties.forEach((x, k) => {
                _row[`${x.Name}_${x.Index}`] = z[defaCols[k]];
            });
            rowData.push(_row);
        })

        setJsonData(rowData);
        setColumnItems(_columnItems);
    }

    const LoadDetails = async (props) => {
        setSelectedColumns(null);
        if (props !== null && props.length > 0) {
            let sessionColumns = await GetStoredColumnsFromSession(
                { FrontendScreenTemplateScreen: screenId, screenIndex, entityName }
            );

            // Set Number of columns you need to display
            const count = props.length; // > 6 ? 5 : props.length;
            if (Helper.IsJSONEmpty(sessionColumns)) {
                for (let i = 0; i < count; i++) {
                    let propTmp = Helper.CloneObject(props[i]);
                    let prop = { ...propTmp, seqNumber: i + 1 };
                    sessionColumns[`${prop.Name}_${prop.Index}_${stepName}`] = prop;
                }
            }

            setSelectedColumns(sessionColumns);
            setState(!state);

            props.forEach(x => {
                x.Value = GetSelectedColumnValue({ sessionColumns, id: `${x.Name}_${x.Index}_${stepName}` })
            });

            setState(!state);
            RenderColumns(props);
        }
    }

    const OnSaveClicked = async (e) => {
        e.preventDefault();
        if (!Helper.IsNullValue(selectedColumns) && !Helper.IsJSONEmpty(selectedColumns)) {
            global.Busy(true);
            const _screen = screens.find(x => parseInt(x.TeScreenId) === parseInt(screenId) && parseInt(x.index) === parseInt(screenIndex));
            let data = {
                ParentEntityType: entityType,
                EntityTypeName: entityName,
                FrontendScreenTemplateScreen: screenId,
                ScreenDescription: screenDescription,
                ScreenLayout: _screen.ScreenLayout,
                properties: selectedColumns,
                NavigationName: navigationName, screenIndex
            };
            UpdateSession(data);
            setSelectedColumns(null);
            setState(!state);
            setTimeout(() => {
                global.Busy(false);
            }, 500);
        }
    }

    const RenderGridActions = () => {

        return {
            headerName: "Actions", type: 'actions', field: "actions", width: 115,
            getActions: ({ row }) => {

                const actions = [];

                actions.push(<GridActionsCellItem
                    icon={
                        <Tooltip title="View" arrow>
                            <VisibilityIcon />
                        </Tooltip>
                    }
                    label="View"
                    className="textPrimary"
                    color="inherit"
                />)

                actions.push(<GridActionsCellItem
                    icon={
                        <Tooltip title="Edit" arrow>
                            <EditIcon />
                        </Tooltip>
                    }
                    label="Edit"
                    className="textPrimary"
                    color="inherit"
                />);

                actions.push(<GridActionsCellItem
                    icon={
                        <Tooltip title="Delete" arrow>
                            <DeleteIcon />
                        </Tooltip>
                    }
                    label="Delete"
                    color="inherit"
                />);

                return actions;
            }
        };

    }

    if (loadEntity) {
        setLoadEntity(false);
        LoadDetails(properties);
    }

    React.useEffect(() => { setLoadEntity(true); }, [properties]);

    return (
        <>
            <Grid item>
                <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
                <Grid container>
                    <Grid item xs>
                        <Box style={{ width: '100%' }}>
                            <Stack direction="row">
                                <Grid container sx={{ justifyContent: 'flex-end' }}>
                                    <SearchInput />
                                    <IconButton
                                        size="medium"
                                        edge="start"
                                        color="inherit"
                                        aria-label="Add"
                                        sx={{
                                            marginLeft: "2px",
                                            borderRadius: "4px",
                                            border: theme.borderBottom
                                        }}
                                    >
                                        <AddBoxIcon />
                                    </IconButton>
                                </Grid>
                            </Stack>
                        </Box>
                        <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
                        <Box style={{ display: 'flex', paddingLeft: "8px", width: '100%', flexDirection: "column" }}>
                            {columnItems && columnItems.length > 0 && (
                                <>
                                    <DataGrid
                                        disableColumnMenu
                                        rows={jsonData}
                                        columns={[...columnItems, RenderGridActions()]}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 5 },
                                            },
                                        }}
                                        pageSizeOptions={[5, 10]}
                                    />
                                </>
                            )}
                        </Box>
                        <Divider sx={{ marginTop: 2 }} />
                        <Box sx={{ padding: 1 }}>
                            {(!Helper.IsJSONEmpty(selectedColumns) || preLoaded) && (<Button variant="outlined" onClick={OnSaveClicked}>Save</Button>)}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

const RenderWorkSpace = (props) => {
    const { screens, entityType, properties, navigationName, entityName, stepName } = props;
    const [loadEntity, setLoadEntity] = React.useState(false);
    const [selectedProperties, setSelectedProperties] = React.useState([]);
    const [maxItems, setMaxItems] = React.useState(0);
    const [selectedColumns, setSelectedColumns] = React.useState({});
    const [state, setState] = React.useState(false);
    const { screenId, screenIndex } = Helper.GetQueryParams();

    const onDropDownSelected = (e) => {
        let lastColumns = selectedColumns || {};
        const { options, value, name, seqNumber } = e;
        if (value === 'NONE') {
            delete lastColumns[name];
        } else {
            let _field = options.find(x => x.DisplayName === value);
            _field = { ..._field, seqNumber };
            lastColumns[name] = _field;
        }
        setSelectedColumns(lastColumns);
        setState(!state);
    }

    const GetSelectedColumnValue = (e) => {
        let rtnVal = "NONE";
        if (!Helper.IsNullValue(e.sessionColumns) && !Helper.IsJSONEmpty(e.sessionColumns[e.id])) {
            rtnVal = e.sessionColumns[e.id].DisplayName || "NONE";
        }
        return rtnVal;
    }

    const LoadDetails = async (props) => {
        setSelectedColumns(null);
        setSelectedProperties([]);
        setMaxItems(0);
        if (props !== null && props.length > 0) {
            let sessionColumns = await GetStoredColumnsFromSession(
                { FrontendScreenTemplateScreen: screenId, screenIndex, entityName }
            );

            // Set Number of columns you need to display
            const count = props.length; // > 6 ? 5 : props.length;
            if (Helper.IsJSONEmpty(sessionColumns)) {
                for (let i = 0; i < count; i++) {
                    let propTmp = Helper.CloneObject(props[i]);
                    let prop = { ...propTmp, seqNumber: i + 1 };
                    sessionColumns[`${prop.Name}_${prop.Index}_${stepName}`] = prop;
                }
            }

            setSelectedColumns(sessionColumns);
            setState(!state);

            props.forEach(x => {
                x.Value = GetSelectedColumnValue({ sessionColumns, id: `${x.Name}_${x.Index}_${stepName}` })
            });


            setSelectedProperties(props);
            setMaxItems(count);
            setState(!state);
        }
    }

    const OnSaveClicked = async (e) => {
        e.preventDefault();
        if (!Helper.IsNullValue(selectedColumns) && !Helper.IsJSONEmpty(selectedColumns)) {
            global.Busy(true);
            const _screen = screens.find(x => parseInt(x.TeScreenId) === parseInt(screenId) && parseInt(x.index) === parseInt(screenIndex));
            let data = {
                ParentEntityType: entityType,
                EntityTypeName: entityName,
                FrontendScreenTemplateScreen: screenId,
                ScreenDescription: screenDescription,
                ScreenLayout: _screen.ScreenLayout,
                properties: selectedColumns,
                NavigationName: navigationName, screenIndex
            };
            UpdateSession(data);
            setSelectedColumns(null);
            setState(!state);
            setTimeout(() => {
                global.Busy(false);
            }, 500);
        }
    }

    if (loadEntity) {
        setLoadEntity(false);
        setMaxItems(0);
        LoadDetails(properties);
    }

    React.useEffect(() => { setLoadEntity(true); }, [properties]);

    return (
        <>
            <Grid item>
                <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
                <Grid container>
                    <Grid item xs>
                        <Box style={{ display: 'flex', paddingLeft: "8px", width: '100%', flexDirection: "column" }}>
                            {maxItems > 0 && Array.from({ length: maxItems }).map((_, i) => {
                                const x = selectedProperties[i];
                                return (
                                    <Box key={i} sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                                        <Box sx={{ width: "25%" }}>
                                            <PropertySelectList keyId={`${x.Name}_${x.Index}_${stepName}`} selectedColumns={selectedColumns}
                                                nameId={'DisplayName'} valueId={'DisplayName'} value={x.Value} fieldSequenceNumber={i + 1}
                                                options={selectedProperties} onDropDownChange={onDropDownSelected} />
                                        </Box>
                                        <Box sx={{ display: 'flex', minWidth: "30px", alignItems: 'center' }}>
                                            <Typography>{x.value || "Field Value here"}</Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                        <Divider sx={{ marginTop: 2 }} />
                        <Box sx={{ padding: 1 }}>
                            {!Helper.IsJSONEmpty(selectedColumns) && (<Button variant="outlined" onClick={OnSaveClicked}>Save</Button>)}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

const Component = (props) => {

    const { entities } = props;

    const [state, setState] = React.useState(false);

    const [loadEntity, setLoadEntity] = React.useState(false);
    const [entityType, setEntityType] = React.useState(null);

    const [selectedSteps, setSelectedSteps] = React.useState({});
    const [stepOptions, setStepOptions] = React.useState([]);
    const [stepItems, setStepItems] = React.useState([]);

    const theme = useTheme();

    const onDropDownSelected = (e) => {
        let lastColumns = selectedSteps || {};
        const { options, value, name } = e;
        if (value === 'NONE') {
            delete lastColumns[name];
        } else {
            const _field = options.find(x => x.id === value);
            const _entities = entities.find(x => x.Name === _field.entityName);
            const _nonCollections = _entities.Properties?.filter(x => !x.IsCollection) || [];
            lastColumns[name] = { ..._field, Properties: _nonCollections };
        }
        setSelectedSteps(lastColumns);
        setState(!state);
    }

    const OnEntitySelected = async (e) => {
        setEntityType(null);
        if (e && e.length > 0) {
            setEntityType(e[0].EntityType);
        }
    }

    const GetDisplayName = (x) => {
        return x.Property ? x.Name : `${x.Name} (${x.Type})`;
    }

    const EntityFilters = async () => {

        setStepOptions([]);
        setStepItems([]);
        setSelectedSteps({});

        if (entities && entities.length > 0) {
            let filteredItems = [];

            if (entityType) {
                let _filteredEntities = entities.find(x => x.Name === entityType).Properties;

                // Non navigation properties
                let _property = _filteredEntities.filter(x => x.FieldType === "Property").map(z => {
                    return { Type: z.Type, Name: z.Type, Property: true, IsCollection: z.IsCollection }
                });
                _property = Helper.RemoveDuplicates(_property, 'Type');
                filteredItems = _property;

                // Non stream navigation properties
                _property = _filteredEntities.filter(x => x.Type && x.FieldType === "NavigationProperty" && !x.HasStream).map(z => {
                    return { Type: z.Type, Name: z.Name, NavigationName: z.Name, IsCollection: z.IsCollection }
                });
                _property = Helper.RemoveDuplicates(_property, 'Type');
                filteredItems = [...filteredItems, ..._property];

                // stream navigation properties
                _property = _filteredEntities.filter(x => x.Type && x.FieldType === "NavigationProperty" && x.HasStream).map(z => {
                    return { Type: z.Type, Name: z.Name, IsCollection: z.IsCollection }
                });
                _property = Helper.RemoveDuplicates(_property, 'Name');
                filteredItems = [...filteredItems, ..._property];

                // Remove Collections
                // filteredItems = filteredItems.filter(x => !x.IsCollection);

            }
            const tOptions = filteredItems.map((x, index) => {
                return {
                    id: index + 1,
                    name: GetDisplayName(x),
                    entityName: x.Type,
                    navigationName: x.NavigationName,
                    IsCollection: x.IsCollection
                }
            });
            let _stepItems = tOptions.map((x, index) => {
                return {
                    stepId: index + 1,
                    stepName: `STEP_NAME_${index + 1}`
                }
            });

            const count = _stepItems.length > 6 ? 5 : _stepItems.length;
            _stepItems = _stepItems.splice(0, count);
            setStepItems(_stepItems);
            setStepOptions(tOptions);
            setState(!state);
        }

    }

    if (loadEntity) { setLoadEntity(false); EntityFilters(); }

    React.useEffect(() => { setLoadEntity(true); }, [entityType]);

    React.useEffect(() => { setLoadEntity(true); }, [entities]);

    return (
        <Container {...props} onEntitySelected={OnEntitySelected}>
            {stepItems && stepItems.length > 0 && (
                <>
                    <Grid id="oneToManyLayout" container spacing={3} columns={1} paddingTop={2} sx={{ mb: 4 }}>
                        {stepItems.map(x => (
                            <Grid key={x.stepId} item xs={8}>
                                <Grid container>
                                    <Grid item sx={{ width: "40px", padding: theme.spacing(1) }}>
                                        <Avatar sx={{ fontSize: "0.8rem", width: 24, height: 24, backgroundColor: "#1976d2" }}>{x.stepId}</Avatar>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <PropertySelectList keyId={x.stepName} selectedColumns={selectedSteps} contentName={'API'}
                                            nameId={'name'} valueId={'id'} value={x.Value} onDropDownChange={onDropDownSelected}
                                            options={stepOptions} />
                                    </Grid>
                                </Grid>
                                {selectedSteps && selectedSteps[x.stepName] && selectedSteps[x.stepName].Properties && selectedSteps[x.stepName].Properties.length > 0 && !selectedSteps[x.stepName].IsCollection &&
                                    <RenderWorkSpace {...props} properties={selectedSteps[x.stepName].Properties} stepName={x.stepName}
                                        entityName={selectedSteps[x.stepName].name} navigationName={selectedSteps[x.stepName].navigationName} entityType={entityType} />
                                }
                                {selectedSteps && selectedSteps[x.stepName] && selectedSteps[x.stepName].Properties && selectedSteps[x.stepName].Properties.length > 0 && selectedSteps[x.stepName].IsCollection &&
                                    <RenderTableWorkSpace {...props} properties={selectedSteps[x.stepName].Properties} stepName={x.stepName}
                                        entityName={selectedSteps[x.stepName].entityName} navigationName={selectedSteps[x.stepName].navigationName} entityType={entityType} />
                                }
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </Container>
    )
}

export default Component;