import React from 'react';
import {
    Toolbar, List, ListItem, ListItemButton,
    ListItemIcon, ListItemText
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import { Settings as SettingsIcon, Add as AddIcon } from '@mui/icons-material';
import * as MuiIcons from '@mui/icons-material';

import { DRAWER_WIDTH } from "config";
import LinkToScreenPopup from "./linktoscreen";

const openedMixin = (theme) => ({
    width: DRAWER_WIDTH,
    border: 0,
    borderRight: theme.borderColor,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    border: 0,
    borderRight: theme.borderColor,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const CustomDrawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const GenerateIcon = (variation, iconSize) => {
    const IconName = MuiIcons[variation];
    return <IconName sx={{ ...iconSize }} />
};

const Component = (props) => {
    const { open, screens } = props;
    const [expand, setExpand] = React.useState(false);
    const iconSize = { width: 24, height: 24, mr: 0 };
    const [openDialog, setOpenDialog] = React.useState(false);
    const theme = useTheme();

    const handleClick = () => {
        setExpand(!expand);
    };

    const OnLinkToScreenClicked = (e) => {
        e.preventDefault();
        setOpenDialog(true);
    }

    return (
        <CustomDrawer variant="permanent" open={open} anchor="left" theme={theme}>
            <Toolbar />
            <List>
                <ListItem disablePadding sx={{ display: 'block' }}>
                    {open && <ListItemButton sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }} onClick={open ? handleClick : undefined}>
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 1 : 'auto',
                            justifyContent: 'center',
                        }}>
                            <SettingsIcon sx={{ ...iconSize }} />
                        </ListItemIcon>
                        <ListItemText primary={"Configure Menu"} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>}
                    {open && screens && screens.filter(z => z.IsNavigable).map((x, index) => (
                        <ListItemButton sx={{ pl: 4 }} key={index} onClick={OnLinkToScreenClicked}>
                            <ListItemIcon sx={{ minWidth: 30 }}>
                                {GenerateIcon(x.icon, iconSize)}
                            </ListItemIcon>
                            <ListItemText primary={x.ScreenLayout} sx={{ opacity: open ? 1 : 0 }} />
                            <ListItemIcon sx={{ minWidth: 30 }}>
                                <AddIcon sx={{ ...iconSize }} />
                            </ListItemIcon>
                        </ListItemButton>
                    ))}
                </ListItem>
            </List>
            <LinkToScreenPopup screens={screens} openDialog={openDialog} setOpenDialog={setOpenDialog} />
        </CustomDrawer>
    );
}

export default Component;
