import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import { Header, Container, Drawer, AlertMessage } from 'components';
import RouteItems from "./Routes";
import { DarkTheme, LightTheme } from "./theme";
import TimerSession from "shared/useTimerSession";
import { GetEntities, GetQueryParams } from "shared/common";
import Session from "shared/session";
import * as Api from "shared/services";
import Helper from "shared/helper";
import session from "shared/session";
import "./App.css";
import { DEFAULT_ICON } from "config";

global.Busy = (bBool) => {
  var x = document.getElementById("busyloader");
  if (x) x.className = bBool ? "loader display-block" : "loader display-none";
};

global.AlertPopup = (type, msg) => {
  sessionStorage.setItem('alert', JSON.stringify({ msg, type }));
};

const Component = () => {
  const [initialize, setInitialize] = useState(false);
  const [appinitialize, setAppInitialize] = useState(false);
  const [selectedUITemplateConf, setSelectedUITemplateConf] = useState(null);
  const [screensList, setScreensList] = useState([]);
  const [entities, setEntities] = useState([]);
  const [open, setOpen] = useState(false);
  const [customTheme, setCustomTheme] = useState(LightTheme);
  const [theme] = TimerSession('theme');
  const [screenmodified] = TimerSession('screenmodified');

  const OnDrawerClicked = () => { setOpen(!open); }

  const GetAppInfo = async () => {
    global.Busy(true);
    await GetEntities(selectedUITemplateConf?.cAppId).then((entities) => {
      setEntities(entities);
    });

    let screens = Session.Retrieve("screens", true);
    if (Helper.IsJSONEmpty(screens) && selectedUITemplateConf) await GetTemplates(selectedUITemplateConf);
    global.Busy(false);
  }

  const GetTemplates = async (data) => {
    global.Busy(true);
    const screenIdList = Helper.ToString(data.selectedScreenIds).split(",");
    const idList = 'TeScreenId eq ' + screenIdList.join(" or TeScreenId eq ");
    let _all = [];
    await Api.GetTemplatesApi(data.templateId, idList)
      .then(async (res) => {
        global.Busy(false);
        if (res && res.AllScreens) {
          _all = res.AllScreens;
          _all.forEach((elm, index) => {
            elm.index = index; elm.RouteLink = elm.ScreenURL;
            elm.icon = elm.icon || DEFAULT_ICON;
          });
          Session.StoreAsync("screens", _all, true);
          setScreensList(_all);
        }
      })
      .catch((err) => {
        global.Busy(false);
        console.log(err);
      });

  }

  if (initialize) {
    setInitialize(false);
    setSelectedUITemplateConf(GetQueryParams());
  }

  if (appinitialize) { setAppInitialize(false); GetAppInfo(); }

  useEffect(() => {
    let screens = Session.Retrieve("screens", true);
    setScreensList(screens);
  }, [screenmodified]);

  useEffect(() => {
    if (theme === 'Light') {
      setCustomTheme(LightTheme);
    } else if (theme === 'Dark') {
      setCustomTheme(DarkTheme);
    }
  }, [theme]);

  useEffect(() => {
    setInitialize(true);

    const urlParams = new URLSearchParams(window.location.search);
    const receivedToken = urlParams.get('auth');
    if (receivedToken) {
       console.log(receivedToken);
      session.Store("bearer_token",receivedToken);
      urlParams.delete('auth');
      const newUrl = window.location.pathname + '?' + urlParams.toString();
      window.history.replaceState({}, '', newUrl);
    }
  }, []);

  useEffect(() => {
    if (selectedUITemplateConf) {
      if (!Helper.IsJSONEmpty(selectedUITemplateConf)) setAppInitialize(true);
    }
  }, [selectedUITemplateConf]);

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1 }}>
        <Header open={open} onDrawerClicked={OnDrawerClicked} />
        <Drawer open={open} template={selectedUITemplateConf} screens={screensList} entities={entities} />
        <Container open={open}>
          <RouteItems template={selectedUITemplateConf} screens={screensList} entities={entities} />
        </Container>
        <AlertMessage />
      </Box>
    </ThemeProvider>
  );
}

export default Component;
