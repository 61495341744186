import React from "react";
import { Typography } from '@mui/material';

const Component = () => {

    return (

        <>
            <Typography variant="header" component="div" noWrap sx={{ flexGrow: 1 }}>
                Edit Product
            </Typography>
        </>
    );

};

export default Component;