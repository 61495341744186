import React from "react";
import { MenuItem, Select } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const Component = (props) => {
    const { keyId, nameId, valueId, value, options, selectedColumns, sx, onDropDownChange, fieldSequenceNumber, contentName } = props;

    const theme = useTheme();

    const [selectedValue, setSelectedValue] = React.useState(value || 'NONE');
    const [dpOptions, setDpOptions] = React.useState(options || []);
    const [state, setState] = React.useState(false);

    let selectField = [{
        id: -1, value: 'NONE', disabled: true, content: `Select ${contentName || 'Field'}`
    }];
    let removeField = [{ id: -1, value: 'Clear', disabled: false, content: `Remove ${contentName || 'Field'}`, color: theme.palette.error.main }];

    const OnChange = (e) => {
        e.stopPropagation();
        let _tValue = 'NONE';
        if (e.target.value !== 'NONE' && e.target.value !== 'Clear') {
            _tValue = e.target.value;
        }
        setSelectedValue(_tValue);
        setState(!state);
        if (onDropDownChange) onDropDownChange({ options, name: keyId, value: _tValue, seqNumber: fieldSequenceNumber });
    }

    const onOpen = (e) => {
        e.stopPropagation();
        let lastColumns = [];
        let props = selectedColumns || {};
        for (let key in props) lastColumns.push(props[key][valueId]);
        const _options = [];
        options.forEach((x) => {
            if (x[valueId] === selectedValue) {
                _options.push(x);
            } else if (x[valueId] !== selectedValue && lastColumns.indexOf(x[valueId]) === -1) {
                _options.push(x);
            }
        })
        setDpOptions(_options);
        setState(!state);
    }

    React.useEffect(() => {
        const tmp = JSON.parse(JSON.stringify(options));
        setDpOptions(tmp);
    }, [options]);

    return (
        <>
            <Select variant="standard" value={selectedValue || 'NONE'} theme={theme}
                onChange={(e) => OnChange(e)}
                onOpen={(e) => onOpen(e)}
                onBlur={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                MenuProps={{
                    sx: {
                        "&& .Mui-selected": { ...theme.selected }
                    }
                }}
                sx={{ height: "38px", width: "100%", ...sx }}
            >
                {selectField.map((x, index) => (
                    <MenuItem key={1000 + index} disabled={x.disabled} value={x.value} name={x.name} sx={{ color: x.color }}>
                        {x.content}
                    </MenuItem>
                ))}

                {dpOptions.map((x, index) => (
                    <MenuItem key={2000 + index} value={x[valueId]}>
                        {x[nameId]}
                    </MenuItem>
                ))}

                {removeField.map((x, index) => (
                    <MenuItem key={3000 + index} disabled={x.disabled} value={x.value} name={x.name} sx={{ color: x.color }}>
                        {x.content}
                    </MenuItem>
                ))}

            </Select>
        </>
    )
}

export default Component;