import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, TextField } from '@mui/material';
import { Done as DoneIcon, Close as CloseIcon } from '@mui/icons-material';
import Helper from "shared/helper";
import CustomEditIcon from "../eicon";

const Component = ({ value, color, sx, onTitleChanged }) => {
    const [pageTitle, setPageTitle] = useState("");
    const [editTitle, setEditTitle] = useState(false);

    const OnInputChanged = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setPageTitle(value);
    }

    const OnEditClicked = (e) => {
        e.preventDefault();
        setEditTitle(true);
        if (onTitleChanged) onTitleChanged(pageTitle);
    }

    const OnDoneClicked = (e) => {
        e.preventDefault();
        setEditTitle(false);
        if (onTitleChanged) onTitleChanged(pageTitle);
    }

    const OnCancelClicked = (e) => {
        e.preventDefault();
        setPageTitle(value);
        setEditTitle(false);
    }

    useEffect(() => {
        setPageTitle(value);
    }, [value]);

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                {!editTitle && (
                    <>
                        <Typography noWrap variant="subheader" component="div">
                            {Helper.ToString(pageTitle)}
                            <CustomEditIcon onClick={OnEditClicked} />
                        </Typography>
                    </>
                )}
                {editTitle && (
                    <>
                        <TextField id="txtPageTitle" name="txtPageTitle"
                            sx={{
                                "& .MuiOutlinedInput-input": { color },
                                width: "250px",
                                ...sx
                            }}
                            value={Helper.ToString(pageTitle)} onChange={OnInputChanged}
                            size="small" variant="outlined" />
                        <IconButton size="small" onClick={OnDoneClicked}
                            sx={{
                                p: "5px", ml: "5px", backgroundColor: "#cccccc",
                                "&:hover": {
                                    backgroundColor: "#9e9e9e"
                                }
                            }}>
                            <DoneIcon sx={{ color: "#616161", width: 18, height: 18, mr: 0 }} />
                        </IconButton>
                        <IconButton size="small" onClick={OnCancelClicked}
                            sx={{
                                p: "5px", ml: "5px", backgroundColor: "#cccccc",
                                "&:hover": {
                                    backgroundColor: "#9e9e9e"
                                }
                            }}>
                            <CloseIcon sx={{ color: "#616161", width: 18, height: 18, mr: 0 }} />
                        </IconButton>

                    </>
                )}
            </Box>
        </>
    )

};

export default Component;